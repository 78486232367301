import React, { useState, useEffect, useRef  } from 'react';
import io from 'socket.io-client';
import { TextField, Button, Container, Paper, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

const socket = io('https://assessme.io', { transports: ['websocket', 'polling'] });

const InterviewComponent = ({ hash_id }) => {
  const [message, setMessage] = useState('Hi, I am ready to start the interview');
  const [messages, setMessages] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(true); // Состояние для блокировки поля ввода
  const [isControlQuestion, setIsControlQuestion] = useState(false); // Состояние для определения управляющего вопроса
  const [isQuiz, setIsQuiz] = useState(false); // Состояние для определения тестового вопроса
  const [quizAnswers, setQuizAnswers] = useState([]); // Состояние для хранения вариантов ответов
  const history = useNavigate(); // Использование history для перенаправления
  const messagesEndRef = useRef(null); // Референс для прокрутки

  useEffect(() => {
    socket.on('response', (data) => {
      console.log('Received response:', data); // Логируем полученные данные
      if (data.hash_id === hash_id) {
        setMessages((prevMessages) => [...prevMessages, { from: 'GPT', text: data.response }]);

        if (data.is_control_question) {
	console.log('Received control question:', data.response); // Логируем управляющий вопрос
          setIsControlQuestion(true); // Устанавливаем состояние для управляющего вопроса
        } else {
          setIsControlQuestion(false);
        }

	if (data.is_quiz) {
          console.log('Received quiz question:', data.response); // Логируем тестовый вопрос
          setIsQuiz(true); // Устанавливаем состояние для тестового вопроса
          setQuizAnswers(data.quiz_answers.answers); // Устанавливаем массив ответов для тестового вопроса
        } else {
          setIsQuiz(false);
          setQuizAnswers([]);
        }

        if (data.end) {
          setInputDisabled(true); // Блокировка поля ввода после окончания вопросов
        }
	scrollToBottom(); // Прокрутка вниз после получения нового сообщения
      }
    });

    // Отключение сокета при размонтировании компонента
    return () => {
      socket.off('response');
    };
  }, [hash_id]);

  const sendMessage = () => {
    console.log('Sending message:', message, 'with hash_id:', hash_id);  // Добавлено для отладки
    socket.emit('message', [message, hash_id]);
    setMessages((prevMessages) => [...prevMessages, { from: 'You', text: message }]);
    setMessage('');

    if (inputDisabled) {
      setInputDisabled(false); // Разблокировка поля ввода после отправки начального сообщения
    }
   scrollToBottom();
  };

  const handleControlQuestion = (answer) => {
    console.log('Control question answer:', answer, 'with hash_id:', hash_id);  // Добавлено для отладки
    socket.emit('control_answer', [answer, hash_id]);
    setMessages((prevMessages) => [...prevMessages, { from: 'You', text: answer ? 'Yes' : 'No' }]);
    setIsControlQuestion(false); // Сбрасываем состояние управляющего вопроса
  };

  const handleQuizAnswer = (answer) => {
    console.log('Quiz answer:', answer, 'with hash_id:', hash_id);  // Добавлено для отладки
    socket.emit('quiz_answer', [answer, hash_id]);
    setMessages((prevMessages) => [...prevMessages, { from: 'You', text: answer }]);
    setIsQuiz(false); // Сбрасываем состояние тестового вопроса
  };

  const finishInterview = () => {
    history(`/finish/${hash_id}`);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(); // Прокрутка вниз при монтировании компонента
  }, [messages]);

  return (
    <Container maxWidth="md" style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Paper elevation={3} style={{ padding: '20px', width: '100%', height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#f0f0f0', borderRadius: '15px' }}>
        <Typography variant="h4" gutterBottom align="center" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
          Interview
        </Typography>
        <List style={{ overflowY: 'auto', flexGrow: 1, marginBottom: '10px' }}>
          {messages.map((msg, index) => (
            <ListItem key={index} style={{ backgroundColor: msg.from === 'GPT' ? '#e0f7fa' : '#fff3e0', borderRadius: '10px', margin: '5px 0' }}>
              <ListItemText primary={`${msg.from === 'GPT' ? 'Interviewer' : msg.from === 'You' ? 'You' : msg.from}: ${msg.text}`} style={{ wordBreak: 'break-word' }} />
            </ListItem>
          ))}
	  <div ref={messagesEndRef} /> {/* Добавляем ref для прокрутки */}
        </List>
        {inputDisabled && messages.length > 0 && messages[messages.length - 1].from === 'GPT' && messages[messages.length - 1].text.includes('Press the button to finish the test') && (
          <Button variant="contained" color="primary" onClick={finishInterview} style={{ marginBottom: '10px' }}>
            Press the button to finish the test
          </Button>
        )}
        {isControlQuestion ? (
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Button variant="contained" color="success" onClick={() => handleControlQuestion(true)} style={{ flex: 1, marginRight: '10px' }}>
              Yes
            </Button>
            <Button variant="contained" color="error" onClick={() => handleControlQuestion(false)} style={{ flex: 1 }}>
              No
            </Button>
          </Box>
	) : isQuiz ? (
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            {quizAnswers.map((answer, index) => (
              <Button
                key={index}
                variant="contained"
		color = "primary"
                onClick={() => handleQuizAnswer(answer)}
                style={{ flex: 1, margin: '0 5px', marginBottom: '5px', borderRadius: '10px' }}
              >
                {answer}
              </Button>
            ))}
          </Box>
        ) : (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              variant="outlined"
              label="Message"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                }
              }}
              style={{ marginRight: '10px' }}
              disabled={inputDisabled} // Блокировка поля ввода
            />
            <Button variant="contained" color="primary" onClick={sendMessage} endIcon={<SendIcon />} style={{ height: '56px' }}>
              Send
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default InterviewComponent;
