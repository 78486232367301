import React from 'react';

const App = () => {
  return (
    <div>
      <h1>Welcome to the Interview App</h1>
      <p>Please provide a hash_id in the URL.</p>
    </div>
  );
};

export default App;
