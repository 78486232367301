import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import App from './App';
import IntroComponent from './IntroComponent';
import InterviewComponent from './InterviewComponent';
import FinishComponent from './FinishComponent'; // Добавьте этот компонент

const HashWrapper = ({ Component, ...rest }) => {
  const { hash_id } = useParams();
  const [instruction, setInstruction] = React.useState('');

  React.useEffect(() => {
    fetch(`/api/${hash_id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'valid') {
          setInstruction(data.instruction);
        }
      });
  }, [hash_id]);

  return <Component {...rest} hash_id={hash_id} instruction={instruction} />;
};

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/intro/:hash_id" element={<HashWrapper Component={IntroComponent} />} />
      <Route path="/interview/:hash_id" element={<HashWrapper Component={InterviewComponent} />} />
      <Route path="/finish/:hash_id" element={<HashWrapper Component={FinishComponent} />} />	
      <Route path="/" element={<App />} />
      <Route path="*" element={<div>Please provide a hash_id in the URL.</div>} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
