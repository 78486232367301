import React from 'react';
import { Container, Paper, Typography, Button, Box } from '@mui/material';

const IntroComponent = ({ hash_id, instruction }) => {
  const handleStartClick = () => {
    fetch(`/start/${hash_id}`, { method: 'POST' })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'started') {
          window.location.href = `/interview/${hash_id}`;
        }
      });
  };

  return (
    <Container maxWidth="md" style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
      <Paper elevation={3} style={{ padding: '20px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff', borderRadius: '15px' }}>
        <Typography variant="h4" gutterBottom align="center" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
          Interview Instruction
        </Typography>
        <Typography variant="body1" gutterBottom align="center" style={{ fontFamily: 'Arial, sans-serif' }}>
          {instruction}
        </Typography>
        <Box mt={4} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleStartClick} style={{ fontSize: '18px', padding: '10px 20px' }}>
            Start interview
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default IntroComponent;
