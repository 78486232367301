import React from 'react';
import { useParams } from 'react-router-dom';

const FinishComponent = () => {
  const { hash_id } = useParams();

  return (
    <div>
      <h1>Interviewing complete</h1>
      <p>Your hash_id: {hash_id}</p>
    </div>
  );
};

export default FinishComponent;
